import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { GalleryCosmicMetadata } from '@finn/ui-cosmic';
import { Slider } from '@finn/ui-modules';
import { cn, getCloudinaryImgUrl, parseToHtml } from '@finn/ui-utils';
import { useRef } from 'react';

import GalleryItem from './GalleryItem';

const Gallery = ({ data }: { data: GalleryCosmicMetadata }) => {
  const totalImages = data?.images?.length;
  const blockOfThree = Math.floor(totalImages / 3);
  const remaining = totalImages % 3;
  const gridRepeat = blockOfThree * 2 + remaining;
  const firstItemRef = useRef(null);
  const lastItemRef = useRef(null);

  const hasButton = !!data.button_href;

  const isMD = useMediaQuery('md');
  const colWidth = isMD ? '80%' : '50%';

  return (
    <>
      <div className="container">
        <div className="mb-8 sm:mb-10">
          <h2
            className={cn('mobile-t2-semibold md:web-t2-semibold', {
              'mb-6': hasButton,
            })}
          >
            {parseToHtml(data.title)}
          </h2>
          {hasButton && (
            <Button href={data.button_href}>Download press pack as .zip</Button>
          )}
        </div>
      </div>
      <Slider
        isGallery
        firstItemForwardedRef={firstItemRef}
        lastItemForwardedRef={totalImages === 1 ? firstItemRef : lastItemRef}
      >
        <div className={totalImages === 1 ? null : 'container'}>
          <div
            className={cn(
              'grid grid-rows-[214px,214px] gap-6 sm:grid-rows-[320px,320px]',
              totalImages === 1
                ? 'md:grid-rows-[388px,388px]'
                : 'md:grid-rows-[466px,466px]'
            )}
            style={{
              gridTemplateColumns: `repeat(${gridRepeat}, ${
                totalImages === 1 ? '100%' : colWidth
              })`,
            }}
          >
            {data.images.map(({ metadata }, index) => (
              <GalleryItem
                key={metadata.large.url}
                url={getCloudinaryImgUrl(metadata.large.url, {
                  w: data.images.length === 1 ? 2100 : 618,
                  c: 'fill',
                })}
                index={index}
                totalImages={totalImages}
                ref={
                  index === 0
                    ? firstItemRef
                    : index === totalImages - 1
                      ? lastItemRef
                      : null
                }
                alt={metadata.alt}
              />
            ))}
          </div>
        </div>
      </Slider>
      {data.description ? (
        <div className="container">
          <p className="body-12-regular">{data.description}</p>
        </div>
      ) : null}
    </>
  );
};

export default Gallery;
